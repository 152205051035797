<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Department</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'Home'}">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Department</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="card">
        <div class="overlay" v-if="is_loading">
          <i class="fas fa-2x fa-sync-alt fa-spin"></i>
        </div>
        <div class="card-header">
          <h3 class="card-title">Detail</h3>
          <div class="card-tools">
            <router-link class="btn btn-info" :to="{name: 'Departments'}">
              <i class="fas fa-list"></i> List
            </router-link>
          </div>
        </div>
        <div class="card-body">

          <div class="custom-list">
            <ul>
              <li><strong>Organization</strong>{{ !_.isEmpty(department.organization) ? department.organization.org_name : '' }}</li>
              <li><strong>Head of Department</strong>{{ !_.isEmpty(department.admin_user) ? department.admin_user.name : '' }}</li>
              <li><strong>Name</strong>{{ department.name }}</li>
              <li><strong>Description</strong>{{ !_.isEmpty(department.description) ? department.description : '' }}</li>
              <li><strong>Starting Date</strong>{{ !_.isEmpty(department.starting_date) ? department.starting_date : '' }}</li>
              <li><strong>Created At</strong>{{ !_.isEmpty(department.created_at) ? department.created_at : '' }}</li>
              <li><strong>Updated At</strong>{{ !_.isEmpty(department.updated_at) ? department.updated_at : '' }}</li>
            </ul>

          </div>

        </div>
        <div class="card-footer">

        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {DepartmentsAPI} from "@/services/api";

export default {
  name: 'DepartmentShow',
  data: () => ({
    department: {},
    
  }),
  mounted: function () {
    this.departmentShow(this.$route.params.id);
  },
  methods: {
    departmentShow(id) {
      DepartmentsAPI.show(id).then(department => {
        this.department = department;
        console.log(this.department);
      });
    }
  }
}
</script>

